<form class="ark-search-container mat-elevation-z1" [formGroup]="searchForm">
    <div class="main-items">
        <div class="ark-search fx-row">
            @if(isDiscover) {
            <div class="field-container">
                <mat-radio-group 
                    aria-label="Select an option" 
                    formControlName="searchType" 
                    class="ark-sport-select fx-row margin-8px"
                >
                    @for (option of searchTypes; track option) {
                        <mat-radio-button [value]="option">{{ option | titlecase }}</mat-radio-button>
                    }
                </mat-radio-group>
            </div>
            }
            @if(filteredActivities?.length) {
            <div class="field-container">
                <mat-select placeholder="Search for type of activity..." formControlName="gameType" multiple #mySelect
                    class="ark-sport-select ark-search fx-row">
                    <mat-option>
                        <ngx-mat-select-search formControlName="gameFilter"
                            noEntriesFoundLabel="no matching activity types found."
                            placeholderLabel="Search for an activity..."></ngx-mat-select-search>
                    </mat-option>
                    @for (option of filteredActivities; track option) {
                    <mat-option [value]="option">{{option.label}}</mat-option>
                    }
                </mat-select>
            </div>
            } @else {
            <div class="field-container --loading">
                <ngx-skeleton-loader count="1" appearance="line" [theme]="{
                      'width': '100%',
                      'height': '36px',
                    }" />
            </div>
            }
            <span class="spacer"></span>
            @if(isDiscoverReady) {
            <button class="filter-button" mat-stroked-button matTooltip="Clear Filter"
                (click)="onClearFilterButtonClick()" color="accent">
                Reset Filters
            </button>
            <button class="filter-button" mat-stroked-button matTooltip="Toggle Filter"
                (click)="displayOptions = !displayOptions">
                All Filters
            </button>
            <ng-content></ng-content>
            }
        </div>
    </div>

    <div class="ark-content" [class.--visible]="displayOptions">
        <div class="ark-search-type fx-row fx-gap-10x">

            @if(isPickupGames) {
            <ark-state-button [displayed]="exactDateDisplayed" [exists]="!!searchForm.controls.exactDate.value"
                (stateClick)="displayedSearchType = 'ExactDate'" label="Exact Date">
            </ark-state-button>
            } @else if(isAvailablePlayers) {
            <ark-state-button [displayed]="ratingsDisplayed" [exists]="!!(searchForm.controls.ratings.value.length)"
                (stateClick)="displayedSearchType = 'Ratings'" label="Ratings">
            </ark-state-button>

            <ark-state-button [displayed]="daysDisplayed" [exists]="searchForm.controls.days.value.length"
                (stateClick)="displayedSearchType = 'Days'" label="Days Available">
            </ark-state-button>

            <ark-state-button [displayed]="levelsDisplayed" [exists]="!!searchForm.controls.levels.value"
                (stateClick)="displayedSearchType = 'LevelSelect'" label="Select Level">
            </ark-state-button>

            }

            @if(isPickupGames || isTeams){
            <mat-slide-toggle labelPosition="before" formControlName="online"
                (change)="onOnlineChange($event.checked)">Online Only</mat-slide-toggle>
            }

            @if(isTeams && user) {
            <mat-slide-toggle labelPosition="before" formControlName="showFavorites">Only
                Show
                Favorites</mat-slide-toggle>
            }
        </div>

        <div>
            <div class="input-section" [class.input-section--visible]="displayedSearchType === 'LevelSelect'">
                <mat-chip-listbox class="sport-listbox" aria-label="Select the levels you want" formControlName="levels"
                    multiple>
                    @for(level of levels; track level) {
                    <mat-chip-option color="accent">{{ level }}</mat-chip-option>
                    }
                </mat-chip-listbox>
            </div>
            <div class="input-section --calendar" [class.input-section--visible]="displayedSearchType === 'ExactDate'">
                <mat-form-field appearance="outline">
                    <mat-label>Choose a date</mat-label>
                    <input formControlName="exactDate" matInput [matDatepicker]="picker">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <button class="open-btn" mat-icon-button (click)="picker.open()">
                    <mat-icon>calendar_month</mat-icon>
                </button>
            </div>

            <div class="input-section" [class.input-section--visible]="displayedSearchType === 'Days'">
                <!-- Show abbreviations for days on small screens -->
                @if(mobile) {
                <mat-form-field appearance="outline">
                    <mat-label>Days</mat-label>
                    <mat-select formControlName="days" multiple>
                        @for(day of days; track day) {
                        <mat-option [value]="day">{{ day }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                } @else {
                <!-- Show chips on larger screens -->
                <mat-chip-listbox class="sport-listbox" aria-label="Select the days you want" formControlName="days"
                    multiple>
                    @for(day of days; track day) {
                    <mat-chip-option color="accent">{{ day }}</mat-chip-option>
                    }
                </mat-chip-listbox>
                }
            </div>

            <div class="input-section" [class.input-section--visible]="displayedSearchType === 'Ratings'">
                @if(ratings) {
                <mat-form-field appearance="outline" placeholder="Rating">
                    <mat-label>Rating</mat-label>
                    <mat-select formControlName="ratings" class="ratings-input" [multiple]="true">
                        @for(rating of ratings; track rating) {
                        <mat-option [value]="rating.id">{{
                            rating.label }}
                        </mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                }
            </div>
        </div>
    </div>
</form>