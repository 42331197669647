import { createReducer, on } from '@ngrx/store';

import { CurrentState } from '../../state';
import {
  AuthEmailLogin, AuthEmailLoginError, AuthEmailLoginSuccess,
  AuthGoogleLogin, AuthGoogleLoginError, AuthGoogleLoginSuccess,
  AuthLoad,
  AuthLogout,
  AuthLogoutError,
  AuthLogoutSuccess,
  AuthProviderLoginError,
  AuthProviderLoginSuccess,
  AuthSuccess,
  AuthUpdate,
} from './actions';
import { authInitialState, AuthState } from './state';

export const authReducer = createReducer(
  authInitialState,
  on(
    AuthLoad,
    (state: AuthState) => ({ ...state, state: CurrentState.Loading }),
  ),
  on(
    AuthSuccess,
    (state: AuthState, action) => {
      return {
        ...state,
        state: action.state,
        user: action.user ?? null,

      };
    },
  ),

  on(
    AuthLogout,
    (state) => ({ ...state, state: CurrentState.Loading }),
  ),
  on(
    AuthLogoutSuccess,
    (state) => ({
      ...state,
      state: CurrentState.Success,
      user: null,
      login: undefined,
    }),
  ),
  on(
    AuthLogoutError,
    (state, action) => ({
      ...state,
      state: CurrentState.Error,
      error: action.error,
    }),
  ),

  on(
    AuthGoogleLogin,
    (state) => ({
      ...state,
      state: CurrentState.Loading,
      login: undefined,
    }),
  ),
  on(
    AuthGoogleLoginSuccess,
    (state, action) => ({
      ...state,
      state: CurrentState.Success,
      user: action.user,
      login: action.login,
    }),
  ),
  on(
    AuthGoogleLoginError,
    (state, action) => ({
      ...state,
      state: CurrentState.Error,
      error: action.error,
      login: action.login,
    }),
  ),

  on(
    AuthEmailLogin,
    (state) => ({
      ...state,
      state: CurrentState.Loading,
      login: undefined,
    }),
  ),
  on(
    AuthEmailLoginSuccess,
    (state, action) => ({
      ...state,
      state: CurrentState.Success,
      user: action.user,
      login: action.login,
    }),
  ),
  on(
    AuthEmailLoginError,
    (state, action) => ({
      ...state,
      state: CurrentState.Error,
      error: action.error,
      login: action.login,
    }),
  ),

  on(
    AuthUpdate,
    (state) => {
      return {
      ...state,
      state: CurrentState.Pending,
    };
    },
  ),

  on(
    AuthProviderLoginSuccess,
    (state, action) => ({
      ...state,
      state: CurrentState.Success,
      user: action.user,
      login: action.login,
    }),
  ),
  on(
    AuthProviderLoginError,
    (state, action) => ({
      ...state,
      state: CurrentState.Error,
      error: action.error,
      login: action.login,
    }),
  ),
);
