export const ConditionIcons = {
  'Sunny': 'wb_sunny',
  'Clear': 'nightlight',
  'Partly cloudy': 'cloudy_rain',
  'Cloudy': 'wb_cloudy',
  'Overcast': 'wb_cloudy',
  'Mist': 'wb_cloudy',
  'Patchy rain possible': 'cloudy_rain',
  'Patchy snow possible': 'cloudy_snowing',
  'Patchy sleet possible': 'cloudy_snowing',
  'Patchy freezing drizzle possible': 'cloudy_snowing',
  'Thundery outbreaks possible': 'thunderstorm',
  'Blowing snow': 'cloudy_snowing',
  'Blizzard': 'snowing',
  'Fog': 'foggy',
  'Freezing fog': 'foggy',
  'Patchy light drizzle': 'cloudy_rain',
  'Light drizzle': 'cloudy_rain',
  'Freezing drizzle': 'cloudy_snowing',
  'Heavy freezing drizzle': 'cloudy_snowing',
  'Patchy light rain': 'cloudy_rain',
  'Light rain': 'cloudy_rain',
  'Moderate rain at times': 'cloudy_rain',
  'Moderate rain': 'cloudy_rain',
  'Heavy rain at times': 'cloudy_rain',
  'Heavy rain': 'cloudy_rain',
  'Light freezing rain': 'cloudy_snowing',
  'Moderate or heavy freezing rain': 'cloudy_snowing',
  'Light sleet': 'cloudy_snowing',
  'Moderate or heavy sleet': 'cloudy_snowing',
  'Patchy light snow': 'cloudy_snowing',
  'Light snow': 'cloudy_snowing',
  'Patchy moderate snow': 'cloudy_snowing',
  'Moderate snow': 'cloudy_snowing',
  'Patchy heavy snow': 'cloudy_snowing',
  'Heavy snow': 'cloudy_snowing',
  'Ice pellets': 'cloudy_snowing',
  'Light rain shower': 'cloudy_rain',
  'Moderate or heavy rain shower': 'cloudy_rain',
  'Torrential rain shower': 'cloudy_rain',
  'Light sleet showers': 'cloudy_snowing',
  'Moderate or heavy sleet showers': 'cloudy_snowing',
  'Light snow showers': 'cloudy_snowing',
  'Moderate or heavy snow showers': 'cloudy_snowing',
  'Light showers of ice pellets': 'cloudy_snowing',
  'Moderate or heavy showers of ice pellets': 'cloudy_snowing',
  'Patchy light rain with thunder': 'thunderstorm',
  'Moderate or heavy rain with thunder': 'thunderstorm',
  'Patchy light snow with thunder': 'thunderstorm',
  'Moderate or heavy snow with thunder': 'thunderstorm',
};

export const ConditionIconColors = {
  wb_sunny: '#FF9900',
  nightlight: '#8DB7D6',
  cloudy_rain: '#8DB7D6',
  wb_cloudy: '#A9A9A9',
  foggy: '#A9A9A9',
  cloudy_snowing: '#DFDFDF',
  thunderstorm: '#FF9900',
  snowing: '#DFDFDF',
};
