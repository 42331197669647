import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, DestroyRef, Input, Signal, computed, inject, model, signal } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { filter, switchMap } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LocationCardService } from '@ark/services/public-api';
import { takeUntilDestroyed, toObservable, toSignal } from '@angular/core/rxjs-interop';
import { DefaultCity } from '@index/interfaces';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatButtonModule } from '@angular/material/button';
import { FormGroup, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgxGpAutocompleteModule, NgxGpAutocompleteOptions } from '@angular-magic/ngx-gp-autocomplete';
import { ConditionIconColors, ConditionIcons } from '@index/enums/weather';
import { Weather } from '@index/models/weather';
import { HttpClientModule } from '@angular/common/http';

@Component({
  selector: 'ark-location-card',
  standalone: true,
  imports: [
    DatePipe,
    HttpClientModule,
    MatIconModule,
    MatTooltipModule,
    NgxSkeletonLoaderModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    NgxGpAutocompleteModule,
    NgTemplateOutlet,
  ],
  templateUrl: './location-card.component.html',
  styleUrl: './location-card.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArkLocationCardComponent {
  private locationCardService = inject(LocationCardService);
  private destroyRef = inject(DestroyRef);
  @Input()cityStringPrefix = ""

  city = model<DefaultCity>();

  date = Date.now();

  // Weather selectors
  weather: Signal<Weather> = toSignal(toObservable(this.city).pipe(
    filter((city) => !!city),
    takeUntilDestroyed(this.destroyRef),
    switchMap((city) => this.locationCardService.getWeather$(city.name)),
  ));
  conditionIcon = computed<string>(() => ConditionIcons[this.weather()?.condition || ''] || 'wb_sunny');
  conditionIconColor = computed<string>(() =>
    ConditionIconColors[this.conditionIcon()] || '#FF9900',
  );

  // Location selectors
  locationImageSrc: Signal<string> = toSignal(toObservable(this.city).pipe(
    filter((city) => !!city),
    takeUntilDestroyed(this.destroyRef),
    switchMap((city) => this.locationCardService.getLocationImage$(city.name, city.lat, city.lng)),
  ))
  changingLocation = signal(false);

  cityForm = new FormGroup({
    cityName: new UntypedFormControl(''),
  });
  formOptions: NgxGpAutocompleteOptions = {
    types: ['(cities)'],
    componentRestrictions: { country: 'US' },
  };

  changeLocation() {
    this.changingLocation.set(!this.changingLocation());
  }

  onAutocompleteSelected(place: any) {
    if (!place || !place.geometry || !place.geometry.location) {
      return;
    }
    const city = {
      name: place.formatted_address,
      lat: place.geometry!.location.lat(),
      lng: place.geometry!.location.lng(),
    };
    this.city.set(city);
    this.changingLocation.set(false)
    this.locationCardService.changeLocation(city);
  }
}
