import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import { ArkLocationCardComponent, ArkSearchBarComponent } from '@ark';
import { ButtonMenuModule } from '@gth-legacy/directives/button-menu/button-menu.module';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { AppSearchBarComponent } from './search-bar.component';

@NgModule({
  imports: [
    CommonModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RouterModule,
    FormsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatIconModule,
    MatChipsModule,
    MatTooltipModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSlideToggleModule,
    NgxGpAutocompleteModule,
    ButtonMenuModule,
    NgxMatSelectSearchModule,

    ArkSearchBarComponent,
    ArkLocationCardComponent,
  ],
  exports: [AppSearchBarComponent],
  declarations: [AppSearchBarComponent],
})
export class AppSearchBarComponentModule {}
