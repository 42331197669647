import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'ark-state-button',
  standalone: true,
  imports: [
    MatButtonModule,
  ],
  templateUrl: './state-button.component.html',
  styleUrl: './state-button.component.scss'
})
export class ArkStateButtonComponent {
  @Input()
  displayed = false;

  @Input()
  exists = false;

  @Input()
  label = '';

  @Output()
  stateClick = new EventEmitter<void>();

  get buttonColor() {
    return this.exists ? 'primary' : 'accent';
  }

  onButtonClick() {
    this.stateClick.emit();
  }
}
