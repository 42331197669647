import { GthUserModel } from '@sentinels/models';

import { AuthResults } from '../../../../../../gth-legacy/src/lib/services';
import { CurrentState } from '../../state';

export interface AuthState {
    user: GthUserModel | null;
    state: CurrentState;
    error?: unknown;
    login?: AuthResults;
}

export const authInitialState: AuthState = {
    user: null,
    state: CurrentState.Pending,
};
