import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ArkSearchBarComponent, ArkSearchBarContext, ArkSearchBarFilterContract } from '@ark';
import { APP_STATE, eventLoadListByLatLng } from '@gth-legacy';
import { DefaultCity } from '@index/interfaces/user';
import { Store } from '@ngrx/store';
import { GthGameTypeModel } from '@sentinels/models';
import { GthEventRatingModel } from '@sentinels/models/event-rating';
import { SrvApiService } from '@sentinels/services/api.service';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { userLoadListByLatLng } from '@sentinels/state/features/user/actions';
import { BehaviorSubject, combineLatest, Observable, Subscription } from 'rxjs';
import { map, shareReplay, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
export class AppSearchBarComponent implements OnInit, OnDestroy {
  @ViewChild(ArkSearchBarComponent)
  searchBarCtrl: ArkSearchBarComponent;

  @Input()
  pageTitle = '';

  @Input()
  context?: ArkSearchBarContext;

  @Input()
  isDiscover = false;

  @Input()
  defaultCity?: { city: DefaultCity | undefined };

  @Output()
  filter = new EventEmitter<ArkSearchBarFilterContract>();

  ratings: GthEventRatingModel[] = [];
  gameTypes: GthGameTypeModel[] = [];
  isSmallScreen$: Observable<boolean>;
  user = this.store.selectSignal(selectUser);

  // eslint-disable-next-line max-len
  private filterSubject = new BehaviorSubject<ArkSearchBarFilterContract | undefined>(undefined);
  private subscriptions = new Subscription();

  constructor(
    private store: Store<APP_STATE>,
    private api: SrvApiService,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
  ) { }

  async ngOnInit() {
    this.selectUserAndRunSideEffects();

    this.isSmallScreen$ =
      this.breakpointObserver.observe([Breakpoints.XSmall, Breakpoints.Small])
        .pipe(
          map((result) => result.matches),
          shareReplay(),
        );

    this.ratings = await this.api.eventRatings.listAsync();
    const gameTypes = (await this.api.gameTypes.listAsync())
      .sort((a, b) => a.label.localeCompare(b.label));

    const filteredGameTypes: GthGameTypeModel[] = [];
    gameTypes.forEach((g) => {
      const existing = filteredGameTypes
        .find((t) => t.label.toLowerCase() === g.label.toLowerCase());
      if (!existing) filteredGameTypes.push(g);
    });
    this.gameTypes = filteredGameTypes;

    this.activatedRoute.queryParamMap
      .subscribe((map) => {
        if (!this.searchBarCtrl) return;
        this.searchBarCtrl.updateQueryFromParamMap(map);
      });

    const user$ = this.store.select(selectUser).pipe(
      startWith(this.user()),
    );
    const filter$ = this.filterSubject.asObservable();

    this.subscriptions.add(
      combineLatest([filter$, user$]).subscribe(([filter, user]) => {
        if (filter) {
          filter.queryParams.city = user?.defaultCity;
          this.filter.emit(filter);
        }
      }),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setGameType(gameType: string) {
    if (!this.gameTypes || !this.searchBarCtrl) return;
    const validGameType = this.gameTypes
      .find((g) => g.label.toLowerCase() === gameType.toLowerCase());
    if (!validGameType) return;
    this.searchBarCtrl.setActivity(validGameType);
  }

  onSearchPerformed(filter: ArkSearchBarFilterContract) {
    this.filterSubject.next(filter);
  }

  private async selectUserAndRunSideEffects() {
    if (!this.user()) return;
    const latLng = {
      lat: this.user().defaultCity.lat,
      lng: this.user().defaultCity.lng,
    };
    this.dispatchViaUserLocation(latLng);
  }

  private dispatchViaUserLocation(latLng: { lat: number, lng: number }) {
    this.store.dispatch(eventLoadListByLatLng(latLng));
    this.store.dispatch(userLoadListByLatLng(latLng));
  }
}
