
@if (!!cityStringPrefix) {
  <ng-container *ngTemplateOutlet="withCityPrefix"></ng-container>
} @else {
  <ng-container *ngTemplateOutlet="withoutCityPrefix"></ng-container>
}

<!-- Templates-->

<!-- Weather Input-->
<ng-template #WeatherTempl>
  @defer (when !!weather()) {
    <mat-icon [style.color]="conditionIconColor()" [matTooltip]="weather().condition">
      {{ conditionIcon() }}
    </mat-icon>
    <span>{{ weather().temp }}<sup>&deg;F</sup></span>
  } @placeholder {
    <mat-progress-spinner mode="indeterminate" color="white"></mat-progress-spinner>
  }
</ng-template>

<!-- City Input Form-->
<ng-template #cityInputForm>
  <div class="location-changer">
    <form [formGroup]="cityForm">
      <mat-form-field appearance="outline" color="accent">
        <mat-label>Default Location</mat-label>
        <input required class="city-input" matInput #cityInput ngx-gp-autocomplete
          formControlName="cityName" #placesRef="ngx-places" [options]="formOptions"
          (onAddressChange)="onAutocompleteSelected($event)" />
      </mat-form-field>
    </form>
  </div>
</ng-template>

<ng-template #changeLocationButton>
  <button mat-mini-fab class="edit-location-button" (click)="changeLocation()">
    <mat-icon>
      @if (changingLocation()) {
        close
      } @else {
        edit
      }
    </mat-icon>
  </button>
</ng-template>

<!-- When String input is passed in as input-->
<ng-template #withCityPrefix>
  <div class="bg-image">
    @defer (when !!locationImageSrc()) {
      <img [src]="locationImageSrc()" alt="Location Image" />
    } @placeholder {
      <ngx-skeleton-loader [theme]="{ 'width': '100%', 'height': '100%', 'border-radius': '1.25rem' }"></ngx-skeleton-loader>
    }
  </div>
  
  <div class="content content-direction-start right-to-left-gradient">
    <ng-container *ngTemplateOutlet="changeLocationButton"></ng-container>

    <h3 class="city-with-prefix">
      <span>
      {{ cityStringPrefix }}
      </span>
      <u>
        <a (click)="changeLocation()">
          @defer (when !!city()) {
            {{ city().name }}
          } @placeholder {
            <ngx-skeleton-loader [theme]="{ 'width': '200px', 'height': '1.375rem', 'margin-bottom': '-2px', 'opacity': '50%' }" animation="pulse"></ngx-skeleton-loader>
          }
        </a>
      </u>
    </h3>
    <div class="city-date spacer">
      <p>{{ date | date:'fullDate' }}</p>
    </div>   

    <h3>
      <ng-container *ngTemplateOutlet="WeatherTempl"></ng-container>
    </h3>
    
    @if (changingLocation()) {
      <ng-container *ngTemplateOutlet="cityInputForm"></ng-container>
    }
  </div>
</ng-template>

<!-- When String input is not passed in -->
<ng-template #withoutCityPrefix>
  <div class="bg-image">
    @defer (when !!locationImageSrc()) {
      <img [src]="locationImageSrc()" alt="Location Image" />
    } @placeholder {
      <ngx-skeleton-loader [theme]="{ 'width': '100%', 'height': '100%' }"></ngx-skeleton-loader>
    }
  </div>
  
  <div class="content content-direction-end left-to-right-gradient">
    <ng-container *ngTemplateOutlet="changeLocationButton"></ng-container>
  
    <h2>
      <ng-container *ngTemplateOutlet="WeatherTempl"></ng-container>
    </h2>
  
    <div class="city-date">
      <a (click)="changeLocation()">
        <mat-icon inline>location_on</mat-icon>
        @defer (when !!city()) {
          {{ city().name }}
        } @placeholder {
          <ngx-skeleton-loader [theme]="{ 'width': '150px', 'height': '16px', 'margin-bottom': '-3px', 'opacity': '50%' }" animation="pulse"></ngx-skeleton-loader>
        }
      </a>
      <p>{{ date | date:'fullDate' }}</p>
    </div>
  
    @if (changingLocation()) {
      <ng-container *ngTemplateOutlet="cityInputForm"></ng-container>
    }
  </div>
</ng-template>