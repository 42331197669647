import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, catchError, map, of, retry, shareReplay, switchMap, take, timeout, timer } from 'rxjs';
import { APP_STATE, AuthUpdate, eventLoadListByLatLng } from '../../../../../gth-legacy/src/public-api';
import { userLoadListByLatLng, userUpdateOne } from '@sentinels/state/features/user/actions';
import { Store } from '@ngrx/store';
import { DefaultCity } from '@index/interfaces';
import { selectUser } from '@sentinels/state/features/auth/selectors';
import { Weather } from '@index/models/weather';

export const enum GoogleLoadedStatus {
  success,
  fail,
}

export const defaultLocationImage = '/assets/img/location-default.svg'

@Injectable({
  providedIn: 'root',
})
export class LocationCardService {
  private http = inject(HttpClient);
  private store = inject(Store<APP_STATE>);

  user = this.store.selectSignal(selectUser);

  private weatherCache: { [cityName: string]: Observable<Weather> } = {};

  public getWeather$(cityName: string): Observable<Weather> {
    if (!this.weatherCache[cityName]) {
      const url = `https://api.weatherapi.com/v1/current.json`;

      const options = {
        params: new HttpParams().set('q', cityName).set('key', environment.weatherApi),
      };

      this.weatherCache[cityName] = this.http.get(url, options).pipe(
        timeout(5000),
        retry(3),
        map((response: any) => {
          return {
            temp: Math.round(response.current.temp_f),
            condition: response.current.condition.text,
          };
        }),
        catchError((error) => {
          console.error(error);
          return of({ temp: null, condition: null })
        }),
        shareReplay(1),
      );
    }

    return this.weatherCache[cityName];
  }

  public getLocationImage$(name: string, lat: number, lng: number): Observable<string> {
    return this.googleMapsLoader$().pipe(
      take(1),
      map((status) => {
        if (status !== GoogleLoadedStatus.success) {
          throw new Error('Google Maps PlacesService not available.');
        }
      }),
      switchMap(() => this.searchPlace$(name, lat, lng)),
      catchError((error) => {
        console.error(error);
        return of(defaultLocationImage);
      }),
    );
  }

  public changeLocation(city: DefaultCity) {
    this.dispatchUserLocation({ lat: city.lat, lng: city.lng });
    const data = {
      uid: this.user().uid,
      dataToUpdate: {
        defaultCity: {
          lat: city.lat,
          lng: city.lng,
          name: city.name,
        },
      },
    };
    this.store.dispatch(userUpdateOne(data));
    this.store.dispatch(AuthUpdate());
  }

  private googleMapsLoader$(): Observable<GoogleLoadedStatus> {
    return new Observable((observer) => {
      if (typeof google?.maps?.places?.PlacesService !== 'undefined') {
        observer.next(GoogleLoadedStatus.success);
        observer.complete();
      }
      
      const startTime = new Date().getTime();
      const maxWaitTime = 7000; // 7 seconds in milliseconds

      function checkExistence() {
        if (typeof google?.maps?.places?.PlacesService !== 'undefined') {
          observer.next(GoogleLoadedStatus.success);
          observer.complete();
        } else {
          const currentTime = new Date().getTime();
          if (currentTime - startTime >= maxWaitTime) {
            observer.next(GoogleLoadedStatus.success);
            observer.complete();
            // observer.error(new Error('Timeout: PlacesService not available within 7 seconds.'));
          } else {
            setTimeout(checkExistence, 100); // Check again after 100 milliseconds
          }
        }
      }

      checkExistence();
    });
  }

  private searchPlace$(name: string, lat: number, lng: number): Observable<string> {
    return new Observable((observer) => {
      const request = {
        location: { lat, lng },
        radius: 1000,
        keyword: name,
      };

      const service = new google.maps.places.PlacesService(document.createElement('div'));
      service.nearbySearch(request, (results, status) => {
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
          observer.error(new Error('Place search request failed.'));
          return;
        }

        if (!results || results.length === 0) {
          observer.error(new Error('No results found for the given location and keyword.'));
          return;
        }

        const place = results[0];
        const photos = place.photos;
        if (!photos || photos.length === 0) {
          observer.error(new Error('No photos found for this place.'));
          return;
        }

        const closestPhoto = this.findClosestPhoto(photos);
        if (!closestPhoto) {
          observer.error(new Error('No photo found closest to 400px in height.'));
          return;
        }

        const imageUrl = closestPhoto.getUrl();
        observer.next(imageUrl);
        observer.complete();
      });
    });
  }

  private findClosestPhoto(photos: any[]) {
    let closestPhoto = null;
    let minHeightDifference = Infinity;

    for (const photo of photos) {
      const photoHeight = photo.height;

      const heightDifference = Math.abs(photoHeight - 400);
      if (heightDifference < minHeightDifference) {
        closestPhoto = photo;
        minHeightDifference = heightDifference;
      }
    }
    return closestPhoto;
  }

  private dispatchUserLocation(latLng: {lat:number, lng: number}) {
    this.store.dispatch(eventLoadListByLatLng(latLng));
    this.store.dispatch(userLoadListByLatLng(latLng));
    return true;
  }
}
